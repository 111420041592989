.container {
  padding: 24px;
  background-color: #f0f2f5;
  min-height: 100vh;
}

/* Personalizando a cor do Collapse */
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: rgb(252, 186, 3);
  color: white !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: white !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-content {
  border-color: rgb(252, 186, 3);
}

/* Ajustes para mobile */
@media (max-width: 768px) {
  .container {
    padding: 16px;
  }

  .ant-card {
    width: 100% !important;
    max-width: 100% !important;
  }

  .ant-typography h2 {
    font-size: 20px !important; /* Título menor para mobile */
  }

  .ant-typography h4 {
    font-size: 18px !important; /* Subtítulos menores para mobile */
  }
}

@media print {
  body {
      display: none;
  }
}