/* Estilo do spinner */
.loader {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Fundo do spinner */
    border-top: 4px solid rgb(252, 186, 3); /* Cor do spinner */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s ease-in-out infinite; /* Animação de rotação */
  }
  
  /* Animação de rotação */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }